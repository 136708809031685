<template>
  <div>
    <v-container
      class="px-6 pt-0 pb-6"
      fluid
    >
      <v-row
        no-gutters
        class="justify-end mt-4 mb-2"
      >
        <div class="d-flex ml-auto my-1 mx-4">
          <date-picker
            class="mx-3 my-0"
            category="averageSessionTime"
            :min-date="dataInitialDate"
            :max-date="currentDate()"
            @averageSessionTime="onDatePicked"
          />
          <export-stats />
        </div>
      </v-row>
      <section
        id="statsView"
      >
        <v-row no-gutters class="ma-0">
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.totalUsers') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.subscribers')"
                />
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ formattedStats.totalUsers.value }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.activeUsers') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.active')"
                />
              </div>
              <v-divider
                color="primary"
              />
              <h2
                v-if="formattedStats.activeUsers.value"
                class="text-center my-auto"
              >
                {{ formattedStats.activeUsers.value }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.averageSessionTime') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.session')"
                />
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ formattedStats.averageSessionTime.value }}
              </h2>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="8"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.enrollmentsTimeline') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.enrollments')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="enrollmentsTimeline"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div
                class="py-4"
              >
                <line-chart
                  v-if="formattedStats.enrollmentsTimeline.data.length"
                  :chartdata="formattedStats.enrollmentsTimeline.data"
                  :labels="formattedStats.enrollmentsTimeline.labels"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="ma-0">
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.projectsWithMostUsers') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.projects')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="projectsWithMostUsers"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div class="py-4">
                <bar-chart
                  v-if="formattedStats.projectsWithMostUsers.data.length"
                  :chartdata="formattedStats.projectsWithMostUsers.data"
                  :labels="formattedStats.projectsWithMostUsers.labels"
                />
              </div>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="8"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.usersByCountry') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.country')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="usersByCountry"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div
                class="py-4"
                style="overflow-x: auto;
  white-space: nowrap;"
              >
                <vert-bar-chart
                  v-if="formattedStats.usersByCountry.data.length"
                  :chartdata="formattedStats.usersByCountry.data"
                  :labels="formattedStats.usersByCountry.labels"
                  :locale="locale"
                  :style="{
                    width: `${formattedStats.usersByCountry.labels.length * 7}%`
                  }"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="ma-0">
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="mb-0 mr-2">
                  {{ $t('organization.statistics.usersByAge') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.age')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="usersByAge"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div class="py-4">
                <bar-chart
                  v-if="formattedStats.usersByAge.data.length"
                  :chartdata="formattedStats.usersByAge.data"
                  :labels="formattedStats.usersByAge.labels"
                />
              </div>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="8"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="text-start mb-0 mr-2">
                  {{ $t('organization.statistics.timePerProject') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.timeProject')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="timePerProject"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div
                class="py-4 px-6"
              >
                <pie-chart
                  v-if="formattedStats.timePerProject"
                  :chartdata="formattedStats.timePerProject.data"
                  :labels="formattedStats.timePerProject.labels"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { countries } from 'countries-list'
import i18nCountries from '@/utils/mixins/i18nCountries'
import { dateRangeArray, dateRangeArrayByMonth, formatMonthlyData } from '@/utils/charts/dateRangeArray'

export default {
  name: 'Stats',
  components: {
    BarChart: () => import('@/utils/charts/horizBars'),
    LineChart: () => import('@/utils/charts/vertLine'),
    VertBarChart: () => import('@/utils/charts/vertBars'),
    PieChart: () => import('@/utils/charts/pie'),
    ExportStats: () => import('@/modules/stats/ExportOrganizationStats'),
    DatePicker: () => import('@/modules/stats/DatePicker'),
    ModalChart: () => import('@/modules/stats/ModalChart'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [i18nCountries],
  data() {
    return {
      locale: null,
      organizationId: '',
      wasDateChanged: false,
      wasYearSearch: false,
      dateRange: {
        start: this.currentDate(),
        end: this.currentDate(),
      },
      dataInitialDate: null,
      dateRangeStats: [],
      defaultTimelineStats: {},
      defaultTimePerProjectStats: [],
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters('stats', ['stats']),
    formattedStats({ stats }) {
      return {
        totalUsers: this.formatTotalUsers(stats.userCount),
        activeUsers: this.formatActiveUsers(),
        averageSessionTime: this.formatAverageSessionTime(),
        projectsWithMostUsers: this.formatProjectsWithMostUsers(stats.projectsWithMostUsers),
        usersByAge: this.formatUsersByAge(stats.usersByAge),
        enrollmentsTimeline: this.formatEnrollmentsTimeline(),
        usersByCountry: this.formatUsersByCountry(),
        timePerProject: this.formatTimePerProject(),
      }
    },
    formatCountries({ stats: { usersByCountry } }) {
      return Object.entries(usersByCountry)
        .filter(country => country[1])
        .sort((a, b) => b[1] - a[1])
        .map(country => ({ country: { ...countries[country[0]], name: this.countries[country[0]] }, value: country[1] }))
    },
  },
  watch: {
    async dateRange(nextDates, previousDates) {
      let dateRange = []
      let result = []
      const today = this.currentDate()
      // create array of dates within range
      if (new Date(nextDates.end) - new Date(nextDates.start) > 2.628e9) {
        const datesDividedByMonth = dateRangeArrayByMonth(nextDates.start, nextDates.end)
        const monthlyData = await Promise.all(
          datesDividedByMonth.map(month => this.$store.dispatch('stats/searchStatsRange', {
            id: this.organizationId,
            list: month,
          })),
        )
        result = monthlyData.map((month, index) => formatMonthlyData(month, index))
        this.wasYearSearch = true
      } else {
        dateRange = dateRangeArray(nextDates.start || today, nextDates.end || nextDates.start)
        result = await this.$store.dispatch('stats/searchStatsRange', {
          id: this.organizationId,
          list: dateRange,
        })
        this.wasYearSearch = false
      }
      this.dateRangeStats = result
      this.wasDateChanged = true
    },
  },
  async created() {
    this.$store.commit('loader/show')
    this.organizationId = this.$route.params.organizationId
    if (!this.$store.state.stats.dbStats) await this.$store.dispatch('stats/bindStats', this.organizationId)
    this.dateRangeStats = await this.$store.dispatch('stats/searchStatsRange', {
      id: this.organizationId,
      list: [this.currentDate()],
    })
    this.dataInitialDate = await this.$store.dispatch('stats/searchDataInitDate', this.organizationId)
    this.defaultTimelineStats = await this.getdefaultTimelineStats()
    this.defaultTimePerProjectStats = this.dateRangeStats[0]
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
  updated() {
    this.locale = this.$i18n.locale
  },
  methods: {
    currentDate(retro = 1) {
      // default date is 'yesterday', since no records on db for 'today'
      const today = new Date()
      today.setDate(today.getDate() - retro)
      return today.toISOString().slice(0, 10)
    },
    onDatePicked(value) {
      const today = this.currentDate()
      // exclude future dates
      if (new Date(value.end) > new Date(today)) value.end = today
      // no future dates allowed
      if (new Date() < new Date(value.start) || new Date() < new Date(value.end)) {
        this.$store.dispatch('alert/openAlertBox', ['alertError', 'Sorry, no future dates allowed'])
        // db collections start from 2019-05-30
      } else if ((value.start && new Date(value.start) < new Date('2019-05-30')) || (value.end && new Date(value.end) < new Date('2019-05-30'))) {
        this.$store.dispatch('alert/openAlertBox', ['alertError', 'Sorry, no records available before May 30th 2019'])
      }
      this.dateRange = {
        start: value.start,
        end: value.end,
      }
    },
    formatTotalUsers: stats => {
      const value = stats
      return {
        value,
        title: 'Total users',
      }
    },
    formatActiveUsers() {
      let value = this.$t('organization.statistics.noData')
      if (this.dateRangeStats.length) {
        const numOfSessions = this.dateRangeStats.filter(date => date).reduce((acc, curr) => acc + curr.sessionCount, 0)
        if (numOfSessions) value = numOfSessions
      }
      return {
        value,
        title: 'Active users',
      }
    },
    formatAverageSessionTime() {
      let value = this.$t('organization.statistics.noData')
      // calc average in seconds
      const totSecs = this.dateRangeStats
        .filter(date => date && date?.sessionCount && date?.totalSessionTimeInSecs) // filter out corrupted or non existing data
        .reduce((acc, curr) => acc + (curr.totalSessionTimeInSecs / curr.sessionCount), 0)
      const averageInSecs = totSecs / (this.dateRangeStats.length || 1)
      // format time in hh mm ss
      const startingPoint = new Date(0)
      startingPoint.setSeconds(averageInSecs)
      const timeJsFormatted = startingPoint.toISOString().substr(11, 8).split(':')
      if (!timeJsFormatted.every(unit => unit === '00')) {
        value = `${+timeJsFormatted[0] ? `${timeJsFormatted[0]}h` : ''} 
          ${+timeJsFormatted[1] ? `${timeJsFormatted[1]}m` : ''} 
          ${+timeJsFormatted[2] ? `${timeJsFormatted[2]}s` : ''}`
      }
      // format return value
      return {
        value,
        title: 'Average session time',
      }
    },
    formatProjectsWithMostUsers: stats => {
      const labels = stats.map(project => project.name)
      const data = stats.map(project => project.userCount)
      return {
        title: 'Projects with most users',
        labels,
        data,
      }
    },
    formatUsersByAge: stats => {
      const labels = ['1-7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17-18', '19-20', '21-25', '26-30', '31-40', '41-50', '50-100']
      const labelsAndCounts = labels.map(label => ({
        label,
        count: label.includes('-')
          ? Object.entries(stats)
            .filter(([age]) => +age >= +label.split('-')[0] && +age <= +label.split('-')[1])
            .reduce((acc, [, count]) => acc + count, 0)
          : stats[label],
      }))
      return {
        title: 'Users by age',
        labels: labelsAndCounts.map(({ label }) => label),
        data: labelsAndCounts.map(({ count }) => count),
      }
    },
    async getdefaultTimelineStats() {
      // by default, render past two weeks (15 days) worth of data
      const today = this.currentDate()
      const twoWeeksAgo = this.currentDate(15)
      const labels = dateRangeArray(twoWeeksAgo, today)
      const data = await this.$store.dispatch('stats/searchStatsRange', {
        id: this.organizationId,
        list: labels,
      })
      return {
        labels,
        data: data.map(date => date ? date.enrollmentCount : 0),
      }
    },
    formatEnrollmentsTimeline() {
      const formatLabels = labels => labels.map(isoDate => DateTime.fromISO(isoDate).toFormat('LLL dd'))
      const title = 'Enrollments timeline'
      let { labels, data } = this.defaultTimelineStats
      if (!this.wasDateChanged && data) {
        return {
          title,
          labels: formatLabels(labels),
          data,
        }
      }
      if (this.wasDateChanged && data && this.wasYearSearch) {
        return {
          title,
          labels: this.dateRangeStats.map(month => this.$t(`organization.statistics.months.${month.monthLabel}`)),
          data: this.dateRangeStats.map(month => month.enrollmentCount),
        }
      }
      if (this.dateRangeStats) {
        labels = formatLabels(dateRangeArray(this.dateRange.start, this.dateRange.end))
        data = this.dateRangeStats.filter(date => date).map(date => date.enrollmentCount)
      }
      return {
        title,
        labels,
        data,
      }
    },
    formatUsersByCountry() {
      return {
        title: 'Users by country',
        labels: this.formatCountries.map(country => country.country),
        data: this.formatCountries.map(country => country.value),
      }
    },
    formatTimePerProject() {
      // when multiple dates are available, show latest stats as they are representative of the entire period selected
      if (this.isDataLoaded) {
        const latestStats = this.dateRangeStats[this.dateRangeStats.length - 1] || this.defaultTimePerProjectStats
        const sortSessionTimes = latestStats?.projects
          ?.filter(project => project.totalSessionTimeInSecs > 0)
          .map(project => project.totalSessionTimeInSecs)
          .sort((a, b) => b - a)
        const others = {
          name: this.$t('organization.statistics.others'),
          value: sortSessionTimes
            ?.filter((value, index) => index >= 8)
            .reduce((acc, curr) => acc + curr, 0),
        }
        const higherEight = sortSessionTimes
          ?.filter((value, index) => index < 8)
          .map(value => ({ name: latestStats.projects.filter(project => project.totalSessionTimeInSecs === value).map(project => project.name)[0], value }))
          ?? []
        let pieChartData = [...higherEight]
        if (others.value > 0) pieChartData = [...pieChartData, others]
        return {
          title: 'Time spent by users per project',
          labels: pieChartData.map(project => project.name.length > 16 ? `${project.name.substring(0, 16)}...` : project.name),
          data: pieChartData.map(project => project.value),
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-divider {
  border-color: #2acccc;
}

.v-card {
  flex: 1;
  margin: 12px;
  padding: 0 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
}
</style>
